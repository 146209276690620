// IMPORTS
@import (reference) "settings.less";

// SIDEBAR BESTSELLER
.sidebar-bestseller {
    display: flex;
    flex-direction: column;

    > strong {
        padding: 16px 24px;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 0.05em;
        font-family: @sec-font-family;
        text-transform: uppercase;
        color: white;
        background-color: @main-color;
    }
}

.bestseller-products {
    display: flex;
    flex-direction: column;
    border: 1px solid @border-color;

}

.sidebar-product {
    padding: 16px 24px;
    border-bottom: 1px solid @border-color;
    display: flex;
    gap: 16px;
    position: relative;

    &:last-of-type {
        border-bottom: none;
    }

    .sidebar-product-left {
        display: flex;
        flex-direction: column;
        width: 55px;

        > figure {
            width: 55px;
            aspect-ratio: 1;
        }

        .promo-badge {
            display: flex;
            justify-content: center;
            position: static;
        }
    }

    .sidebar-product-right {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .sidebar-product-name {
            font-size: 14px;
            line-height: 1.2;
            font-weight: 700;
            color: @header-color;

            &:after {
                content: '';
                position: absolute;
                inset: 16px 24px;
            }
        }

        .sidebar-product-price {
            display: flex;
            gap: 8px;

            b,
            del {
                line-height: 1.2;
                font-size: 14px;
            }

            del {
                color: #888888;
            }
        }
    }
}

// SIDEBAR BANNERS
.sidebar-banners-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.sidebar-banner {
    aspect-ratio: ~"280/140";

    > b {
        bottom: 12px;
        padding: 7px 12px;
        width: 71%;
        letter-spacing: 0.05em;
        font-size: 16px;
    }
}

// NEWSLETTER
#newsletter {
    position: relative;
    color: white;
    padding: var(--rowGap) 0;
    display: flex;
    justify-content: center;
    gap: 100px;
    align-items: center;
}

.newsletter-bg {
    .center-lr();
    top: 0;
    width: 101vw;
    height: 100%;
    z-index: -1;
    background-color: @main-color;
    background-repeat: repeat-x;
    background-position: 0% center;
    background-size: 660px;

    &:after {
        .full-bg();
        z-index: 0;
        background-color: #313A49;
        opacity: 0.5;
    }
}

.newsletter-header {

    b {
        font-family: @sec-font-family;
        font-weight: 500;
        line-height: 1.2;
        font-size: 32px;
        display: block;
        text-transform: uppercase;
    }

    p {
        margin-top: 2px;
        display: block;
        text-transform: lowercase;
    }
}

.newsletter-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 560px;
    width: 100%;
    position: relative;

    input[type="text"] {
        width: 100%;
        height: 48px;
        background-color: white;
        padding-right: 56px;
        color: @header-color;
        font-size: 16px;
        font-family: @main-font-family;

        &:focus::placeholder {
            color: transparent;
        }

        &::-webkit-input-placeholder {
            color: @font-color;
        }
        &::-moz-placeholder {
            color: @font-color;
        }
        &:-ms-input-placeholder {
            color: @font-color;
        }
        &:-moz-placeholder {
            color: @font-color;
        }
    }

    .popup-trigger {
        position: absolute;
        top: 0;
        right: 0;
        height: 48px;
        width: 56px;
        color: @header-color;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
            .small-icon();
            content: "\e919";
            font-size: 24px;
        }

        @media(min-width: 1280px) {
            &:hover {
                color: @sec-color;
                cursor: pointer;
            }
        }
    }
}

.newsletter-clauses-wrapper.unchecked {

    &:after {
        content: 'Zaznaczenie zgody jest konieczne, aby zapisać się do Newslettera';
        display: block;
        margin-bottom: 10px;
        margin-top: -10px;
        color: @error-color;
    }
}

// LAST VISITED
main > .last-visited-wrapper {

    .products-list {
        grid-template-columns: repeat(var(--prodLastVistedCols), minmax(0, 1fr));
        gap: calc(var(--prodGap) - 1px);
    }

    .product {
        outline: none;
        padding: 0;
        gap: 12px;

        &:nth-child(8) {
            display: none;
        }
    }

    .product-image {
        margin-bottom: 4px;
    }

    a.product-name {
        text-align: start;
        font-size: 14px;
        line-height: 1.2;
    }

    .product-price {
        flex-direction: row;
        align-items: center;
        gap: 8px;
        height: auto;

        del {
            margin-top: 2px;
        }
    }

    .product-wishlist,
    .cartadder {
        display: none !important;
    }

    .product-hover {
        padding: 16px 10px;

        > p {
            margin-top: 0px;
        }
    }
}

// FOOTER
#footer {
    position: relative;

    &:after {
        content: '';
        .center-lr();
        top: 0;
        width: 101vw;
        height: 100%;
        z-index: -2;
        background-color: @main-color;
    }
}

.footer-content {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255,255,255, 0.2);
    color: white;
    padding: var(--rowGap) 0;
    gap: 20px;
}

@media (min-width: 1280px) {
    .footer-content {
        padding-bottom: 44px;
    }
}

.footer-item {

    > strong {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 0.05em;
        font-family: @sec-font-family;
        text-transform: uppercase;
        margin-bottom: 24px;
        display: block;
    }
}

.footer-item.info {

    > strong {
        margin-bottom: 16px;
    }

    ul {
        columns: 2;
        column-gap: 100px;
    }

    a {
        display: inline-block;
        padding: 8px 0;
        color: white;

        @media(min-width: 1280px) {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.footer-item.shortcut {

    ul {
        .grid();
        grid-template-columns: repeat(2, minmax(0, 213px));
        gap: 16px 24px;

        li {
            width: 100%;
        }

        a {
            color: white;
            border: 1px solid #E8E8E8;
            border-radius: 4px;
            padding: 11px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: @sec-font-family;
            text-transform: uppercase;
            font-weight: 500;
            line-height: 1.2;
            letter-spacing: 0.05em;

            @media(min-width: 1280px) {
                &:hover {
                    color: @main-color;
                    background-color: #E8E8E8;
                }
            }
        }
    }
}

.footer-item.contact {
    display: flex;
    flex-direction: column;
    gap: 16px;

    > strong {
        margin-bottom: 8px;
    }

    > a {
        display: flex;
        align-items: center;
        gap: 12px;
        color: white;

        &:before {
            .small-icon();
        }

        &.phone:before {
            content: "\e91a";
        }

        &.email:before {
            content: "\e919";
        }
    }
}

.footer-bottom-bar {
    display: flex;
    justify-content: space-between;
    padding: 32px 0;

    > a,
    > span {
        color: white;
    }
}